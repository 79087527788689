
import moment from "moment";
import { View } from "react-native";
import Schedule from '../components/schedules/Schedule';
import { ScheduleTaskbar } from '../components/schedules/ScheduleTaskbar';
import { globalStyles } from '../constants/GlobalStyles';



export default function ScheduleScreen({ navigation, route }) {

  const today = moment();
  const minDate = moment().subtract(1, 'y')
  const maxDate = moment().add(3, 'y')

  return (
    <View style={globalStyles.container}>
      <ScheduleTaskbar navigation={navigation} route={route} scheduleType="0"></ScheduleTaskbar>
      <Schedule navigation={navigation} route={route} minDate={minDate.format('MM-DD-YYYY')} maxDate={maxDate.format('MM-DD-YYYY')} scheduleType="0"></Schedule>
    </View>
  );
}