import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
  Linking,
} from "react-native";
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import AxiosContext from '../../components/AxiosContext';
import axios from 'axios';
import { GroupSettings, LifeOarGroup } from "../../types/oarTypes";
import { FontAwesome, FontAwesome5, FontAwesome6 } from '@expo/vector-icons';
import AddGroupScreen from '../Groups/AddGroupScreen';
import linking from "../../navigation/linkingConfiguration";
import { ListComponent } from "../../components/groups/GroupListComponent";
import * as GroupService from "../../services/Groups/groupsService";
import { globalStyles } from "../../constants/GlobalStyles";
import { getGroupsTestData } from "../../tests/API/testData/groupsTestData"
import { LoadingSpinnerComponent } from "../../components/LoadingSpinner";
import { seaBlueColor } from "../../constants/Colors";
import AdComponent from "../../components/ads/AdComponent";
import { ThemedView } from "../../components/ThemedView";

export default function GroupScreen({ navigation, route }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [groupData, setGroupData] = useState<LifeOarGroup[]>([]);

  React.useEffect(() => {
    loadGroupData();
  }, [navigation, route]);

  function loadGroupData() {
    GroupService.getGroups().then((results) => {
      setGroupData(results);
      setIsLoading(false);
    })
    
  };

  function deleteGroup(groupId: string) {
    setIsLoading(true)
    GroupService.deleteGroup(groupId).then((results) => {
      loadGroupData();
    })
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <>
        <View style={globalStyles.container}>
          <View style={globalStyles.commandBarContainer}>
            <View style={globalStyles.headerButtonsContainer}>
              <Pressable onPress={() => navigation.navigate('AddGroup')}>
                <FontAwesome6 name="plus" size={25} color={"black"} style={{ marginLeft: 15 }} />
              </Pressable>
              <Pressable onPress={() => loadGroupData()}>
                <FontAwesome name="refresh" size={22} color={"black"} style={{ marginLeft: 15, marginTop: 2 }} />
              </Pressable>
              {/* <AdComponent/> */}
            </View>
          </View>
          <View style={globalStyles.centeredContainer}> 
            
              <ListComponent navigation={navigation} listOfItems={groupData} onDelete={deleteGroup} isLoading={isLoading}></ListComponent>
            
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  bodyContainer: {
    //backgroundColor: "blue",
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%"
  },
  button: {
    backgroundColor: 'green',
    width: '40%',
    height: 40
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  group: {
    margin: 5,
    backgroundColor: 'grey',
    color: 'white',
    fontSize: 15,
    padding: 10
  },
  item: {
    padding: 10,
    fontSize: 12,
    marginTop: 2,
  }
});

