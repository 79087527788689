import { FontAwesome5, FontAwesome6 } from '@expo/vector-icons';
import React, { useState } from "react";
import { Pressable, SafeAreaView, Text, View, VirtualizedList } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import { LoadingSpinnerComponent } from '../LoadingSpinner';


export function ListComponent({ navigation, listOfItems, onDelete, isLoading }: any) {

  const getItem = (data: any[], index: number) => (
    {
      id: `${data[index]._id}`,
      title: `${data[index].name}`
    }
  );
  const getListItemCount = (data: any[]) => {
    if (typeof data == "undefined") {
      return 0
    }
    else return data.length
  }

  function ListIcon(props: {
    name: React.ComponentProps<typeof FontAwesome6>['name'];
    color: string;
  }) {
    return <FontAwesome6 size={25}{...props} />;
  }

  const Item = ({ item }) => (
    <View style={globalStyles.listItemContainer}>
      <View style={{ flexDirection: "row", width: '95%', flexWrap: "nowrap", flex: 4 }}>
        <Pressable style={[globalStyles.listItem, { flex: 1 }]} onPress={() => navigation.navigate('GroupDetail', { 'groupId': `${item.id}` })} >
          <View style={{ flex: 1, width: 'auto', flexDirection: "row", alignItems: "center" }}>
            <ListIcon name="users" color="black"></ListIcon>
            <Text style={[globalStyles.listItemTitle, { paddingLeft: 5 }]}>{item.title}</Text>
          </View>
        </Pressable>
      </View>
      <View style={{ flex: 1, flexDirection: "row-reverse", paddingRight: 10, alignItems: "center" }}>
        <Pressable onPress={() => onDelete(item.id)} >
          <ListIcon name="times-circle" color="black"></ListIcon>
        </Pressable>
        <Pressable style={{ paddingRight: 10 }} onPress={() => navigation.navigate('EditGroup', { 'groupId': `${item.id}` })}>
          <ListIcon name="edit" color="black"></ListIcon>
        </Pressable>
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <>
          <SafeAreaView style={{ minWidth: "95%", flex: 1 }}>
            <VirtualizedList style={globalStyles.listContainer}
              data={listOfItems}
              initialNumToRender={10}
              renderItem={({ item }) => <Item item={item} />}
              keyExtractor={item => item.id}
              getItemCount={getListItemCount}
              getItem={getItem}
            />
          </SafeAreaView>
      </>
    );
  }
}
