import { FontAwesome5 } from '@expo/vector-icons';
import { Switch } from "@rneui/themed";
import moment from "moment";
import React, { useState } from "react";
import {
  Pressable,
  Text,
  View
} from "react-native";
import { LoadingSpinnerComponent } from "../../components/LoadingSpinner";
import UserInfoContext from '../../components/UserInfoContext';
import { ChatComponent } from "../../components/chat/ChatComponent";
import * as GlobalDefaultHeader from '../../components/header/globalDefaultHeader';
import { LocationListComponent } from "../../components/locations/LocationListComponent";
import { LocationsTaskbar } from "../../components/locations/LocationsTaskbar";
import Schedule from "../../components/schedules/Schedule";
import { ScheduleTaskbar } from "../../components/schedules/ScheduleTaskbar";
import { TaskComponent } from "../../components/tasks/TaskListComponent";
import { NewTaskTaskbar } from "../../components/tasks/TaskTaskbar";
import * as Colors from "../../constants/Colors";
import { seaBlueColor } from "../../constants/Colors";
import { globalStyles } from "../../constants/GlobalStyles";
import * as GroupService from "../../services/Groups/groupsService";
import * as LocationService from '../../services/Locations/locationsService';
import * as TaskService from '../../services/Tasks/tasksService';
import { GroupComponents, LifeOarGroup, LifeOarLocation, LifeOarTask, LifeOarUser } from "../../types/oarTypes";
import { useIsFocused } from '@react-navigation/native';

export default function GroupDetailScreen({ route, navigation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [groupDetailData, setGroupDetailData] = useState<LifeOarGroup>([]);
  const [groupTaskData, setGroupTaskData] = React.useState<LifeOarTask[]>([]);
  const [groupLocationData, setGroupLocationData] = React.useState<LifeOarLocation[]>([]);
  const [visibleComponent, setVisibleComponent] = useState<GroupComponents>(route.params.component || GroupComponents.Chat)
  const [isChatModerated, setIsChatModerated] = useState<boolean>(true);
  const [groupUsers, setGroupUsers] = useState<LifeOarUser[]>([]);
  const { userInfo } = React.useContext(UserInfoContext);
  const isFocused = useIsFocused();


  React.useEffect(() => {
    loadGroupUsers(route.params.groupId).then((results) => {
      loadTaskData()
    })
    loadGroupDetailData(route.params.groupId);
    
    loadLocationData()
    setIsLoading(false);
  }, [route, navigation, isFocused]);

  const loadTaskData = async() => {
    GroupService.getGroupTasks(route.params.groupId).then((results) => {
      results.forEach((task: LifeOarTask) => {
        if(task.assigned != null && task.assigned != "" && groupUsers != null){
          var user = groupUsers.find(u => u.objectId == task.assigned)
          task.assignedName = user.name
        }
      })
      setGroupTaskData(results);
    
    })
  };

  const loadGroupUsers = (groupId: string) => {
    return GroupService.getGroupUsers(groupId).then((results) => {
      setGroupUsers(results);
      return results;
    })
  }

  const deleteGroupTask = (groupId: string, taskId: string) => {
    setIsLoading(true)
    GroupService.deleteGroupTask(groupId, taskId).then(() => {
      loadTaskData();
    })
    setIsLoading(false);
  };

  const editGroupTask = (groupId: string, taskId: string, updatedTask) => {
    setIsLoading(true)
    GroupService.updateGroupTask(groupId, taskId, updatedTask, groupTaskData.find(t => t._id == taskId).assigned).then(() => {
      loadTaskData();
    })
  };

  const deleteGroupLocation = (locationId: string, groupId: string) => {
    setIsLoading(true)
    LocationService.deleteGroupLocation(locationId, groupId).then(() => {
      loadLocationData();
    })
    setIsLoading(false);
  };

  const loadLocationData = () => {
    GroupService.getGroupLocations(route.params.groupId).then((results) => {
      setGroupLocationData(results);
    })
    setIsLoading(false);
  };

  const toggleIsChatModeratedSwitch = (value: any) => {
    setIsChatModerated(value);
    GroupService.updateChatModerationSetting(route.params.groupId, value).then((results: LifeOarGroup) => {
      setGroupDetailData(results);
      setIsLoading(false);
    })
  }

  const loadGroupDetailData = (groupId: string) => {
    GroupService.getGroupDetail(groupId).then((results: LifeOarGroup) => {
      setGroupDetailData(results);

      if (results.groupSettings != null && results.groupSettings.isChatModerated != null) {
        setIsChatModerated(results.groupSettings.isChatModerated)
      }

      navigation.setOptions({
        title: results.name,
        headerTitleStyle: { color: Colors.seaBlueColor },
        headerLeft: (props) => {
          return GlobalDefaultHeader.GlobalHeaderLeft({ userInfo, navigation, props })
        }
      });
    })
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  }

  else {
    let component;
    if (visibleComponent == GroupComponents.Chat) {
      component = <>
        <View style={globalStyles.centeredContainer}>
          <ChatComponent route={route} navigation={navigation}></ChatComponent>
        </View>
      </>;
    }
    if (visibleComponent == GroupComponents.Tasks) {
      component = <>
        <View style={globalStyles.centeredContainer}>
          <NewTaskTaskbar navigation={navigation} onReloadTaskData={loadTaskData} taskType='1' route={route} onToggleIncludeGroupTasks={false}></NewTaskTaskbar>
          <View style={globalStyles.centeredContainer}>
            <TaskComponent navigation={navigation} route={route} listOfItems={groupTaskData} onDelete={deleteGroupTask} onEdit={editGroupTask} taskType='1'></TaskComponent>
          </View>
        </View>
      </>;
    }
    if (visibleComponent == GroupComponents.Schedule) {
      const minDate = moment().subtract(1, 'y')
      const maxDate = moment().add(3, 'y')
      component = <>
        <View style={globalStyles.centeredContainer}>
          <ScheduleTaskbar navigation={navigation} route={route} scheduleType="1"></ScheduleTaskbar>
          <Schedule navigation={navigation} route={route} minDate={minDate.format('MM-DD-YYYY')} maxDate={maxDate.format('MM-DD-YYYY')} scheduleType="1"></Schedule>
        </View>
      </>;
    }
    if (visibleComponent == GroupComponents.Locations) {
      component = <>
        <View style={globalStyles.centeredContainer}>
          <Text style={globalStyles.title}>Locations</Text>
          <LocationsTaskbar navigation={navigation} onReloadLocationData={loadLocationData} route={route}></LocationsTaskbar>
          <LocationListComponent route={route} navigation={navigation} listOfItems={groupLocationData} onDelete={deleteGroupLocation}></LocationListComponent>
        </View>
      </>;
    }

    // if (visibleComponent == GroupComponents.Contacts) {
    //   component = <>
    //     <View style={globalStyles.container}>
    //       <Text style={globalStyles.title}>Contacts</Text>
    //     </View>
    //   </>;
    // }

    return (
      <View style={globalStyles.container}>
        <View style={globalStyles.commandBarContainer}>
          <View style={globalStyles.headerButtonsContainer}>
            <Pressable onPress={() => setVisibleComponent(GroupComponents.Chat)}>
              <FontAwesome5 name="comment-dots" size={25} color='black' style={{ marginLeft: 15 }} />
            </Pressable>
            <Pressable onPress={() => setVisibleComponent(GroupComponents.Tasks)}>
              <FontAwesome5 name="list-ol" size={25} color='black' style={{ marginLeft: 15, marginTop: 2 }} />
            </Pressable>
            <Pressable onPress={() => setVisibleComponent(GroupComponents.Schedule)}>
              <FontAwesome5 name="calendar-alt" size={25} color='black' style={{ marginLeft: 15, marginTop: 2 }} />
            </Pressable>
            <Pressable onPress={() => setVisibleComponent(GroupComponents.Locations)}>
              <FontAwesome5 name="location-arrow" size={25} color='black' style={{ marginLeft: 15, marginTop: 2 }} />
            </Pressable>
            {visibleComponent == GroupComponents.Chat &&
              <View style={{ flexDirection: "row", alignSelf: "center", alignItems: "center" }}>
                <Text style={{ paddingLeft: 15 }}>Chat moderated:  </Text>
                <Text style={{ paddingRight: 15, fontWeight: 'bold' }}>{isChatModerated == true ? "Yes" : "No"}</Text>
                <Switch color={seaBlueColor} value={isChatModerated} style={{ transform: [{ scaleX: 1.0 }, { scaleY: 1.0 },] }}
                  onValueChange={(value) => toggleIsChatModeratedSwitch(value)} />
              </View>
            }
          </View>
        </View>
        {component}
        <UserInfoContext.Consumer>
          {
            ({ userInfo }: any) => (
              <>
              </>
            )}
        </UserInfoContext.Consumer>
      </View>
    );
  }
}

